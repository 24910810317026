import { defineStore } from "pinia";
import { useUserStore } from "./user";
import axios from "axios";
import axiosRetry from "axios-retry";
import AES from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import { useSocketStore } from "./socket";

export const useRequesterStore = defineStore("requester", () => {
  const userX = useUserStore();
  const socketX = useSocketStore();

  const deffaultHeader = {
    "Content-Type": "application/json",
  };

  const client = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30000,
    transformRequest: [
      function (data, headers) {
        if (data) {
          if (!headers.isPublic) {
            const key = userX.cryptoKey;
            const crypted = AES.encrypt(JSON.stringify(data), key).toString();
            const dataToSend = { data: crypted };
            return JSON.stringify(dataToSend);
          } else {
            const crypted = AES.encrypt(
              JSON.stringify(data),
              import.meta.env.VITE_CRIPTO_KEY
            ).toString();
            const dataToSend = { data: crypted };
            return JSON.stringify(dataToSend);
          }
        } else {
          return data;
        }
      },
    ],
    transformResponse: [
      function (data, headers) {
        try {
          if (!data) {
            return { error: "emptyData", data };
          }
          let dataObj;
          if (!headers.isPublic && !headers.ispublic) {
            const key = userX.cryptoKey;
            dataObj = JSON.parse(AES.decrypt(data, key).toString(encUtf8));
          } else {
            dataObj = JSON.parse(
              AES.decrypt(data, import.meta.env.VITE_CRIPTO_KEY).toString(
                encUtf8
              )
            );
          }
          return dataObj ?? data;
        } catch (error) {
          return { error: "decryptError", data };
        }
      },
    ],
  });

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log("entro error");
      const origitalRequest = error.config;
      if (error.response) {
        if (error.response.status === 403 && !origitalRequest._retry) {
          try {
            origitalRequest._retry = true;
            console.log("original requestttt issssss");
            console.log(origitalRequest);
            const body = {
              token: userX.tokens.token,
              refreshToken: userX.tokens.refreshToken,
            };

            console.log("sendToRefresh: ", body);

            try {
              const freshTokens = await Post({
                route: "/refresh-token",
                body,
                addHeader: {
                  isPublic: true,
                  "Access-Control-Expose-Headers": "isPublic",
                },
              });

              console.log("apires: ", freshTokens);

              userX.setTokens(freshTokens.data);
            } catch (error) {
              console.log(error);
              if (error.response.data.error === "decryptError") {
                const decrypted = JSON.parse(
                  AES.decrypt(
                    error.response.data.data,
                    import.meta.env.VITE_CRIPTO_KEY
                  ).toString(encUtf8)
                );
                console.log(decrypted);
                userX.setTokens(decrypted);
              }
            }

            setTimeout(() => {
              return client(origitalRequest);  
            }, 1000);
            
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else if (error.response.status === 401) {
          await Get({
            route: "/logout",
            withAuth: true,
          });
          const { useFirebaseStore } = await import("./firebase");
          const firebaseX = useFirebaseStore();
          await firebaseX.logout();
          userX.setApiUser(null);
          socketX.controlRoom = null;
          userX.cleanConfig();
        }
      }
      return Promise.reject(error);
    }
  );

  const clientRetry = axiosRetry(client, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (e) => {
      return e.response && e.response.status === 403;
    },
    onRetry: (retryCount, error, requestConfig) => {
      requestConfig.headers.Authorization = "Bearer " + userX.tokens.token;
      console.log("newHeaderReplace: ", requestConfig.headers);
    },
  });

  const outClient = axios.create();

  function makeHeader(withAuth, addHeader) {
    return {
      ...deffaultHeader,
      ...(withAuth
        ? {
            Authorization: "Bearer " + userX.tokens.token,
          }
        : {}),
      ...addHeader,
    };
  }

  async function Get({
    route,
    withAuth = false,
    addHeader = {},
    external = false,
    params = {},
  }) {
    const cli = external ? outClient : client;
    if(withAuth && !userX.tokens.token){
      return {};
    }
    return cli.get(route, {
      headers: makeHeader(withAuth, addHeader),
      params,
    });
  }

  async function Put({
    route,
    withAuth = false,
    addHeader = {},
    body = {},
    external = false,
  }) {
    const cli = external ? outClient : client;
    return cli.put(route, body, { headers: makeHeader(withAuth, addHeader) });
  }

  async function Post({
    route,
    withAuth = false,
    addHeader = {},
    body = {},
    external = false,
  }) {
    const cli = external ? outClient : client;
    return cli.post(route, body, {
      headers: makeHeader(withAuth, addHeader),
    });
  }

  async function Delete({
    route,
    withAuth = false,
    addHeader = {},
    external = false,
  }) {
    const cli = external ? outClient : client;
    return cli.delete(route, { headers: makeHeader(withAuth, addHeader) });
  }

  const PostNoEncrypt = (route, withAuth = false, body = {}, addHeader = {}) => {
    const head = makeHeader(withAuth, addHeader);
    return fetch(`${import.meta.env.VITE_API_URL}${route}`, {
      method: "POST",
      headers:head,
      body:JSON.stringify(body)
    });

  }

  return { client, clientRetry, outClient, Get, Put, Post, Delete, PostNoEncrypt };
});
